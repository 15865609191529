@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;700&display=swap');

$poppins: "Poppins", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$lato: "Lato", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$family-sans-serif: $lato;
$family-secondary: $poppins;
$title-family: $family-secondary;

$hillel-blue: #213e8c;
$hillel-red: #ff6161;

$primary: $hillel-red;
$info: $hillel-blue;

.app {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

@import "bulma/bulma.sass";